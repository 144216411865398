<template>
  <container class="artists-list py-16">
    <h1 class="text-3xl font-bold text-center uppercase mb-16">artists</h1>
    <div class="mb-16">
      <button class="button primary mx-auto w-full md:w-96" @click="createNew">
        Add New
      </button>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10">
      <template
        v-for="artist in listOfArtists"
        :key="artist && artist.id"
      >
        <artist-card v-if="artist != null" :artist=artist class="cursor-pointer" @click="editArtist(artist.id)" />
        <div
          v-else
          class="placeholder-card overflow-hidden rounded-2xl bg-gray-100"
          :style="{ 'padding-bottom': '100%' }"
        ></div>
      </template>
    </div>
    <button class="button dark mt-20 mx-auto w-full md:w-96" @click="handleLoadMore">
      View All Artists
    </button>
  </container>
</template>

<script>
import ArtistCard from "@/components/ArtistCard.vue";
import Container from "@/components/Container.vue";
import { computed } from "vue";
import { useRouter } from "vue-router";
import useArtistsWithPagination from "@/hooks/useArtistsWithPagination.js";

export default {
  name: "List",
  components: {
    Container,
    ArtistCard,
  },
  setup() {
    const paginatedArtists = useArtistsWithPagination();
    const router = useRouter();
    const listOfArtists = computed(() => paginatedArtists.listOfArtists.value);
    const hasMore = computed(() => paginatedArtists.hasMore.value);

    paginatedArtists.load();

    const handleLoadMore = async () => {
      paginatedArtists.loadMore();
    };

    function createNew() {
      router.push('/artists/new');
    }

    function editArtist(id) {
      router.push({
        name: 'artist',
        params: { id: id },
      });
    }

    return {
      editArtist,
      listOfArtists,
      hasMore,
      handleLoadMore,
      createNew,
    };
  },
};
</script>

<style scoped>
</style>
